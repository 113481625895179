.filterModal {

    .row {
        display: flex;
        flex-direction: row;
    }

    h5 {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .variableTypesFilterList,
    .variableStatesFilterList {
        width: 50%;



        .filterRow {

            display: flex;
            height: 32px;

            input[type=checkbox].sc-tds-checkbox {
                align-self: center;
            }

            .filterLabel {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 112px;
                text-transform: capitalize;
            }
        }
    }

    .title {
        text-align: left;
    }

    .selectVersionButtonsFieldset {
        border: 0;
        margin: 0;
        min-width: 0;
        padding: 0;
    }
}