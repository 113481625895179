.guage-specific-fields {
    display: flex;
    flex-direction: column;

    .guage-row-1 {
        .row-1-field {
            flex: 1;
        }
    }

    .guage-row-2 {
        .accordion {
            margin-top: var(--tds-spacing-element-24);
            flex:1;
        }
    }
}