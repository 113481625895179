.typeIcon {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    align-content: center;
    justify-content: center;
    text-align: center;
    position: relative;
    display: inline-block;

    &:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        bottom: 110%;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--tds-grey-800);;
        font: var(--tds-detail-05);
        color: var(--tds-white);
        padding: 5px 10px;
        border-radius: 5px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        
      }
    &:hover::after {
        opacity: 1;
      }
}
.variableTypesFilterList .typeIcon {
  &:hover::after {
        content: none;
        opacity: 0;
        
      }
}