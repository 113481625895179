
.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .left-aligned {
        display: flex;
        flex-shrink: 0;
        flex-direction: row;

        h5 {
            margin: auto !important;
        }
    }

    .right-aligned {
        margin-left: auto;
    }

    // CSS for future action buttons. 
    .action-buttons{
        .tds-u-gap2.tds-row {
            align-items: flex-start;
        }
            
    }
}