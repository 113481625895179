/* BEGIN - CSS Reset */

/*
  Allow percentage-based heights in the application.
*/
html,
body,
#root,
.App {
  height: 100%;
}
/*
  Improve text rendering on MacOS.
*/
body {
  -webkit-font-smoothing: antialiased;
}
/*
  Improve media defaults.
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  width: 100%;
}
/*
  Remove built-in form typography styles.
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  Prevent text from overflowing its container.
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* END - CSS Reset */