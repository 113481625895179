.versionIcon {
    border-radius: 4px;
    background-color: var(--tds-positive);
    justify-content: center;
    align-content: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 4px;
    width: 40px;
    height: 24px;
    text-align: center;
    &.bg-green{
        background-color: var(--tds-positive);
    }
    &.bg-grey-868{
        background-color: var(--tds-grey-868);
    }
}