.variableListHeader {
    display: flex;
    flex-wrap: wrap;
    
    margin: 0;
    background-color: var(--tds-grey-300);

    /* .fixed { */
       // max-width: 102px;
        /* Adjust width as needed 
    }*/

   /*  .flexible {
        flex: 1;
        max-width: 320px;
    } */

    .fixedIcon {
        max-width: 50px;
        margin-left: 0;

        button {
            border: none;
            background: transparent;
            cursor: pointer;
        }
    }

    :nth-child(1).listHeaderItem {
        margin-left: 0;
    }

     .row1{ 
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: var(--tds-spacing-layout-8) var(--tds-spacing-layout-16);
        border-bottom: 0.2px solid var(--tds-grey-500);
    .column1 {
        flex: 1; /* 50% width */
        vertical-align: middle;
        line-height: 30px;
        font: "Scania Sans CY Semi Condensed";
        font-weight: 700;
        font-size: 14px;

    }
    .column2 {
        flex: 1; 
        text-align: right; 
    }
    }
    .row2 {
        flex: 1;
        border-bottom: 0.2px solid var(--tds-grey-500);
    }
}

label{
    padding:0 !important
}

.variableListData {
    width: 100%;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: scroll;

    .name-column {
        text-overflow: ellipsis;
        max-width: 112px;
        overflow: hidden;
        white-space: nowrap;
        align-content: center;
    }

    .id-column {
        max-width: 60px;
        align-content: center;
    }

    .version-column {
        max-width: 40px;
        align-self: flex-end;
    }

    .type-column {
        max-width: 25px;
    }

}
.expanded-row{
    cursor: pointer;
    .name-col{
        text-overflow: ellipsis;
        max-width: 330px;
        overflow: hidden;
        white-space: nowrap;
        align-content: center;
    }
    .version-col{
        display: flex;
        align-items: end;
    }
}