.form-container {
    overflow-y: scroll;
    max-height: calc(100vh - 210px);

    &.create {
        max-height: calc(100vh - 136px);
    }

    .divider-width {
        width: 100%;
    }
}