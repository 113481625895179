.timeseries-fields {
    display: flex;
    flex-direction: column;

    .row-1,
    .row-2 {
        display: flex;
        flex-direction: row;
        gap: var(--tds-spacing-element-16);

        .row-1-field,
        .row-2-field {
            flex: 1;
        }

    }

    .row-3 {

        .accordion {
            margin-top: var(--tds-spacing-element-24);
            flex: 1;
        }
    }

}