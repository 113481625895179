.ListHeader {
    display: flex;
    flex-direction: row;
    padding: var(--tds-spacing-layout-16);
    margin: 0;
    background-color: var(--tds-grey-300);
    max-height: 64px;

    .fixed {
        max-width: 102px;
        /* Adjust width as needed */
    }

    .flexible {
        flex: 1;
        max-width: 320px;
    }

    .fixedIcon {
        max-width: 50px;
        margin-top: 27px;
        margin-left: 0;

        button {
            border: none;
            background: transparent;
            cursor: pointer;
        }
    }

    :nth-child(1).listHeaderItem {
        margin-left: 0;
    }

    .listHeaderItem {
        margin-left: var(--tds-spacing-layout-8);
        text-align: left;
        flex: 1;
    }
}

.variableListData {
    display: flex;
    flex-direction: row;
    width: 100%;

    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: scroll;
    cursor: pointer;
    position: relative;
    .name-column {
        text-overflow: ellipsis;
        max-width: 112px;
        overflow: hidden;
        white-space: nowrap;
        align-content: center;
    }

    .id-column {
        max-width: 60px;
        align-content: center;
    }

    .version-column {
        max-width: 40px;
        margin-right: 20px;
    }

    .type-column {
        max-width: 25px;
    }

}

form{
    height:100%
}
