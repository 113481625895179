.container {
    margin: 16px 32px 16px 32px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: var(--tds-spacing-element-32);

    .edit-mode-message{
        display: flex;
        flex-direction: column;
    }
}