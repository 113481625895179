.overlay-div {
    width:100%;
    height:calc(100% - 150px);
    position: absolute;
    
    background-color: rgba(255, 0, 0, 0.5);
    z-index: 1; /* Overlay on top of base div */   
}

.multiline-tooltip {
    white-space: pre-line;
    max-width: 300px; /* Adjust based on your layout */
    word-wrap: break-word;
}

.textarea-wrapper {
    position: relative;
  }
  
.textarea-footer {
display: flex;
justify-content: space-between;
font-size: 12px;
color: #666;
margin-top: 4px;
}