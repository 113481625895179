.chain-display-component {
    width: 100%;
    height: auto;
    overflow-y: scroll;

    .__json-pretty__ {
        line-height: 1.5;
        color: var(--tds-grey-958);
        background: var(--tds-white);
        overflow: auto;

        .__json-key__ {
            color: #42ac0a
        }

        .__json-string__ {
            color: #d3422e
        }

        .__json-value__ {
            color: #d3422e
        }

        .__json-boolean__ {
            color: #d3422e
        }
    }

    .__json-pretty-error__ {
        line-height: 1.3;
        color: #fd971f;
        background: #1e1e1e;
        overflow: auto
    }
}