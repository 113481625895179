.tds-tooltip.sc-tds-tooltip {
    max-width: 100ex;
}
.tds-row {
    padding-bottom: 10px;
}
.medium_emphasis {
    opacity: 60%;
}
.circle {
    color: white;
    padding: 5px;
    display: inline-block;
    border-radius: 50%;
    margin-left: 10px;
}
.circle.red {
    background-color: #bb2828;
}
.circle.green {
    background-color: #04AA6D;
}