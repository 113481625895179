.searchBar {
    display: flex;
    position: relative;

    input {
        border: 0px solid var(--tds-grey-300);
        padding: 8px 0;
        line-height: 30px;
        width: 100%;
        padding: var(--tds-spacing-layout-8) var(--tds-spacing-layout-16);
        padding-left: 43px;
        background: transparent;
        &:focus-visible {
            outline: none;
            background-color: var(--tds-grey-500);
        }
    }

    .searchIcon {
        position: absolute;
        left: 16px;
        top: 15px;
    }

    ::-webkit-input-placeholder {
        font-size: 12px;
    }
}