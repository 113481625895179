@import url('./reset.css');
@import url('@scania/tegel/dist/tegel/tegel.css');

body {
  font-family: 'Scania Office','Segoe UI', 'Helvetica Neue',sans-serif;
}

@media screen and (max-width: 650px) {
  .tabs {
    width: 300px;
    margin: auto;
  }
}

@media screen and (max-width: 992px) {
  .mobile-nav-item {
    display: block;
  }
}
