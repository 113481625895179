.spinner, .percentage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  vertical-align: middle;
  height: 100%;
  opacity: 1;
  background-color: transparent;
  z-index: 100;
}