.layout {

  display: flex;
  flex-direction: column;
  /* Stacks rows vertically */
  height: 100vh;
  /* Set container height to 100vh for viewport height */
}

tds-header {
  width: 100%;
  flex: 0 0 auto;
  /* Set first row to fixed height */
}

.main {
  display: flex;
  min-height: calc(100vh - 64px);

  .landing-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--tds-spacing-element-16);
    padding-top: 20vh;
    align-items: center;

    svg {
      width: 300px;
      height: 50px;
    }

    .helper-text {
      text-align: center;
      color: var(--tds-grey-700);
      font-weight: bold;
    }

  }
}

.content {
  display: flex;
  flex: 1;
  /* Allow second row to grow and fill remaining space */
}

.content.left {
  border-right: 1px solid #B0B7C4;
  flex: 0 0 512px;
  /* Set fixed width for first column (optional) */
  flex-direction: column;
}

.content.right {
  flex-direction: column;
  position: relative;

  .vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Optional for horizontal centering */
    width: 100%;
    height: 100%;
  }
}